/* Reset styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Dark background styling */
.login-body {
  background-color: #121212;
  color: #ffffff;
  font-family: 'Arial', sans-serif;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
}

/* Main container */
.container {
  background: #1e1e1e;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.1);
  max-width: 500px;
  width: 100%;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
}

/* Heading styles */
h1 {
  font-size: 26px;
  color: #ffcc00;
  margin-bottom: 10px;
}

p {
  font-size: 16px;
  color: #bbbbbb;
  margin-bottom: 20px;
}

/* Animated login icon */
.login-icon {
  font-size: 40px;
  margin-bottom: 20px;
  animation: bounce 1.5s infinite alternate;
}

/* Input Fields */
.input-field {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  background-color: #2a2a2a;
  border: 1px solid #444;
  border-radius: 5px;
  color: white;
  font-size: 16px;
}

.input-field::placeholder {
  color: #999;
}

/* Error and Success Messages */
.error-text {
  color: #ff4444;
  font-size: 14px;
}

.password-container {
  position: relative;
  display: flex;
  align-items: center;
}

.password-container .input-field {
  flex: 1;
  padding-right: 40px; /* Ensure space for the eye button */
}

.eye-button {
  position: absolute;
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}


.success-text {
  color: #4caf50;
  font-size: 14px;
}

/* CTA Button */
.cta-button {
  display: inline-block;
  width: 100%;
  padding: 12px;
  background-color: #ffcc00;
  color: #121212;
  text-decoration: none;
  font-weight: bold;
  border-radius: 5px;
  transition: background 0.3s ease-in-out;
  cursor: pointer;
  font-size: 16px;
}

.cta-button:hover {
  background-color: #e6b800;
}

/* Footer */
.footer-text {
  font-size: 14px;
  color: #bbbbbb;
  margin-top: 15px;
}

.link {
  color: #ffcc00;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.footer {
  font-size: 14px;
  color: #888888;
  margin-top: 20px;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0px); }
}

@keyframes bounce {
  0% { transform: translateY(0px); }
  100% { transform: translateY(-10px); }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .container {
    padding: 20px;
    max-width: 90%;
  }
  h1 {
    font-size: 22px;
  }
  p {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 20px;
  }
  p {
    font-size: 13px;
  }
  .cta-button {
    padding: 10px 20px;
  }
}
